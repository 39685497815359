import { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AdFooter, AnalyticsContext, Maybe, NavigationLink } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { useAppContext, useFacilityContext, useOfficeLocationContext } from '@/context';
import {
	resolveLinkTitle,
	resolveLinkUrl,
	findCompanyGroup,
	isNavLink,
} from '@/components/FooterLite/FooterLite.helpers';
import { ONE_TRUST_TOGGLE } from '@/constants';
import { PrivacyOptions } from '@/assets/SVG';

import {
	smallScreenWrapper,
	getAccordionStyles,
	accordionSummaryStyles,
	getWrapperStyles,
	getScheduleContainerStyles,
	getNavLinkStyles,
	getNavTitleStyles,
	accordionDetailsBoxStyles,
	footerLinkTextBoxDesktopStyles,
} from './FooterNavigationContent.styles';

export interface IFooterNavigationContent {
	navigationGroups: Maybe<AdFooter['navigationGroupsCollection']>;
	withScheduling: boolean;
	isSmallDevice: boolean;
	isLightFooter?: boolean;
}

declare global {
	interface Window {
		OneTrust?: {
			ToggleInfoDisplay: () => void;
		};
	}
}

const isOneTrustLink = (navigationLink: NavigationLink | null) => {
	return navigationLink && isNavLink(navigationLink) && navigationLink.externalUrl?.includes(ONE_TRUST_TOGGLE);
};

function SchedulingContent({
	navigationGroups,
	isLightFooter,
}: Readonly<Omit<IFooterNavigationContent, 'withScheduling' | 'isSmallDevice'>>) {
	const companyGroup = findCompanyGroup(navigationGroups?.items);

	if (!companyGroup) return null;

	const navigationLinks = companyGroup.navigationLinksForGroupCollection?.items;

	if (!navigationLinks) return null;

	return (
		<Container sx={getScheduleContainerStyles} data-test-id="section_footer_scheduling_content">
			{navigationLinks?.map((navigationLink, idx) => {
				const navigationLinkTitle = resolveLinkTitle(navigationLink);

				if (navigationLinkTitle) {
					return (
						<TAGLink
							key={navigationLink?.sys.id}
							href={resolveLinkUrl(navigationLink)}
							linkSx={{ textDecoration: 'underline' }}
							dataTestId={`link_schedule_${navigationLink?.sys.id || idx}`}
							onClick={
								isOneTrustLink(navigationLink as NavigationLink)
									? (e) => {
											e.preventDefault();
											window?.OneTrust?.ToggleInfoDisplay();
									  }
									: undefined
							}
						>
							<Box
								sx={footerLinkTextBoxDesktopStyles(
									isOneTrustLink(navigationLink as NavigationLink) || false
								)}
							>
								{isOneTrustLink(navigationLink as NavigationLink) && (
									<PrivacyOptions
										dataTestId={`privacy_icon_${navigationLink?.sys.id ?? idx}`}
										verticalAlign="middle"
									/>
								)}
								<Typography
									lineHeight="1.5rem"
									color={isLightFooter ? 'text.interactive' : 'text.light'}
									variant="bodyLargeBold"
									justifyContent="flex-start"
								>
									{navigationLinkTitle}
								</Typography>
							</Box>
						</TAGLink>
					);
				}

				return null;
			})}
		</Container>
	);
}

function SmallScreenFooter({
	navigationGroups,
	isLightFooter,
}: Pick<IFooterNavigationContent, 'navigationGroups' | 'isLightFooter'>) {
	const [selectedAccordion, setSelectedAccordion] = useState<string | null>(null);
	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();
	const contextData = {
		config,
		officeInfo,
		officeLocationInfo,
	};
	const groupItems = navigationGroups?.items;

	if (!groupItems) return null;

	function handleAccordionChange(accordionId?: string | null) {
		if (!accordionId) return;

		setSelectedAccordion((prev) => (prev === accordionId ? null : accordionId));
	}

	return (
		<Box data-test-id="section_footer_navigation_content_small" sx={smallScreenWrapper}>
			{groupItems.map((group) =>
				group ? (
					<Accordion
						key={group.sys.id}
						sx={getAccordionStyles(isLightFooter as boolean)}
						expanded={selectedAccordion === group.sys.id}
						data-test-id={`accordion_footer_${group.sys.id}`}
						onChange={() => handleAccordionChange(group.sys.id)}
						disableGutters
					>
						<AccordionSummary
							data-test-id={`accordion_footer_${group.sys.id}_title`}
							expandIcon={
								<ExpandMoreIcon
									fontSize="large"
									sx={{ color: isLightFooter ? 'text.interactive' : 'text.light' }}
								/>
							}
						>
							{group?.navigationGroupTitle ? (
								<Typography
									variant="bodyLargeBold"
									color={isLightFooter ? 'text.interactive' : 'text.light'}
									sx={accordionSummaryStyles}
								>
									{group?.navigationGroupTitle}
								</Typography>
							) : group?.navigationGroupLink ? (
								<Typography
									variant="bodyLargeBold"
									color={isLightFooter ? 'text.interactive' : 'text.light'}
									sx={accordionSummaryStyles}
								>
									{resolveLinkTitle(group.navigationGroupLink)}
								</Typography>
							) : null}
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={accordionDetailsBoxStyles} data-test-id={`accordion_details_${group.sys.id}`}>
								{group.navigationLinksForGroupCollection?.items.map((navigationItem) =>
									navigationItem ? (
										<TAGLink
											key={navigationItem.sys.id}
											href={resolveLinkUrl(navigationItem, contextData)}
											dataTestId={`link_for_group_${navigationItem.sys.id}`}
											noLinkStyle
											analyticsContext={navigationItem.analyticsContext}
											onClick={
												isOneTrustLink(navigationItem as NavigationLink)
													? (e) => {
															e.preventDefault();
															window?.OneTrust?.ToggleInfoDisplay();
													  }
													: undefined
											}
										>
											{isOneTrustLink(navigationItem as NavigationLink) && (
												<PrivacyOptions
													dataTestId={`privacy_icon_${navigationItem.sys.id}`}
													verticalAlign="middle"
												/>
											)}
											<Typography
												variant="bodyMediumBook"
												color={isLightFooter ? 'text.interactive' : 'text.light'}
											>
												{resolveLinkTitle(navigationItem)}
											</Typography>
										</TAGLink>
									) : null
								)}
							</Box>
						</AccordionDetails>
					</Accordion>
				) : null
			)}
		</Box>
	);
}

export default function FooterNavigationContent({
	navigationGroups,
	withScheduling,
	isSmallDevice,
	isLightFooter,
}: IFooterNavigationContent) {
	const theme = useTheme();
	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();
	const contextData = {
		config,
		officeInfo,
		officeLocationInfo,
	};

	if (withScheduling) return <SchedulingContent navigationGroups={navigationGroups} isLightFooter={isLightFooter} />;

	if (isSmallDevice) return <SmallScreenFooter navigationGroups={navigationGroups} isLightFooter={isLightFooter} />;

	const groupItems = navigationGroups?.items;

	if (!groupItems) return null;

	return (
		<Box data-test-id="section_footer_navigation_content" sx={getWrapperStyles(groupItems.length)}>
			{groupItems.map((group) =>
				group ? (
					<Box key={group?.sys.id} display="flex" flexDirection="column" gap={1.5}>
						{group?.navigationGroupTitle ? (
							<Typography
								data-test-id={`link_group_title_${group.sys.id}`}
								variant="bodyLargeBold"
								title={group.navigationGroupTitle}
								color={isLightFooter ? 'text.interactive' : 'text.light'}
							>
								{group.navigationGroupTitle}
							</Typography>
						) : group?.navigationGroupLink ? (
							<TAGLink
								dataTestId={`link_group_title_${group.sys.id}`}
								href={resolveLinkUrl(group.navigationGroupLink, contextData)}
								analyticsContext={
									group.navigationGroupLink.analyticsContext ||
									({ clickPosition: 'footer' } as AnalyticsContext)
								}
							>
								<Typography
									variant="bodyLargeBold"
									color={isLightFooter ? 'text.interactive' : 'text.light'}
									sx={getNavTitleStyles(theme)}
									title={resolveLinkTitle(group.navigationGroupLink)}
								>
									{resolveLinkTitle(group.navigationGroupLink)}
								</Typography>
							</TAGLink>
						) : null}

						{group.navigationLinksForGroupCollection?.items.map((navigationItem) =>
							navigationItem ? (
								<TAGLink
									key={navigationItem.sys.id}
									href={resolveLinkUrl(navigationItem, contextData)}
									openInNewTab={(navigationItem as NavigationLink)?.openInNewTab || false}
									dataTestId={`link_for_group_${navigationItem.sys.id}`}
									noLinkStyle
									analyticsContext={
										navigationItem.analyticsContext ||
										({ clickPosition: 'footer' } as AnalyticsContext)
									}
									onClick={
										isOneTrustLink(navigationItem as NavigationLink)
											? (e) => {
													e.preventDefault();
													window?.OneTrust?.ToggleInfoDisplay();
											  }
											: undefined
									}
								>
									<Box
										sx={footerLinkTextBoxDesktopStyles(
											isOneTrustLink(navigationItem as NavigationLink) || false
										)}
									>
										{isOneTrustLink(navigationItem as NavigationLink) && (
											<PrivacyOptions
												dataTestId={`privacy_icon_${navigationItem.sys.id}`}
												verticalAlign="bottom"
											/>
										)}
										<Typography
											color={isLightFooter ? 'text.interactive' : 'text.light'}
											variant="bodyMediumBook"
											title={resolveLinkTitle(navigationItem)}
											sx={getNavLinkStyles(theme)}
										>
											{resolveLinkTitle(navigationItem)}
										</Typography>
									</Box>
								</TAGLink>
							) : null
						)}
					</Box>
				) : null
			)}
		</Box>
	);
}
